import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { getHeaders } from '@src/request';
import { Formik, Field, FastField } from 'formik';
import Moment from 'react-moment';
import moment from 'moment';
import StringMask from 'string-mask';
import Draggable from 'react-draggable';
import { NotificationManager } from 'react-notifications';
import {
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableBody,
    SttText,
    SttButton,
    SttGrid,
    SttMaskedInput,
    SttDatePicker,
    SttTextItem,
    SttLoading,
    SttCard,
    SttCardContent,
    SttInput,
    SttHeading,
    SttBox,
    SttTranslateHook,
    SttNotification
} from '@stt-componentes/core';
import { CPF, CNS, NOME, DATA_NASCIMENTO } from './fieldNames';
import initialValues from './estadoInicial';
import validationSchema from './validationSchema';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        zIndex: 1000,
        position: 'absolute',
        border: '1px solid #d3d4d5',
        boxShadow: '4px 4px 4px 2px rgba(158,158,158,0.8)',
        borderRadius: theme.spacing(0.5),
        [theme.breakpoints.down('md')]: {
            width: '80%',
        },
    },
    body: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
        maxHeight: '500px',
        overflowY: 'auto',
    },
    title: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
    },
    footer: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        display: 'flex',
        justifyContent: 'right',
    },
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    card: {
        padding: theme.spacing(1)
    }
}));

const CnesCadsus = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { open, setOpen } = props;
    const schema = validationSchema(strings);
    const classes = useStyles();
    const formatterCPF = new StringMask('000.000.000-00');
    const formatterCNS = new StringMask('000 0000 0000 0000');

    const [carregando, setCarregando] = useState(false);
    const [dados, setDados] = useState(null);

    useEffect(() => {
        if (!open) {
            setDados(null);
        }
    }, [open]);

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const siglaSexo2Descricao = (sigla) => {
        switch (sigla) {
            case 'F':
                return strings.feminino;
            case 'M':
                return strings.masculino;
            case 'O':
                return strings.outro;
            default:
                return strings.desconhecido;
        }
    }

    return (
        open &&
        <div>
            <Draggable cancel=".MuiButtonBase-root, .MuiInputBase-root, .body" positionOffset={{ x: '-50%', y: '-50%' }}>
                <SttBox className={classes.modal} component={Paper}>
                    <SttBox className={classes.title}>
                        <SttHeading variant="h3" color="primary" component="div">{strings.cnesCadsus}</SttHeading>
                    </SttBox>
                    <SttBox className={[classes.body, 'body']}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={(data, { setSubmitting }) => {
                                setCarregando(true);
                                let id = data.cpf || data.cns;
                                id = id.replace(/\D/g, '');
                                const query = {};
                                if (id) {
                                    query._id = id;
                                }
                                if (data[NOME] && data[DATA_NASCIMENTO]) {
                                    query.name = data[NOME].trim().toUpperCase();
                                    query.birthdate = moment(data[DATA_NASCIMENTO]).format('YYYY-MM-DD');
                                }

                                axios
                                    .get(`${ADM_API_BASE_URL}/funcionario-cadsus`, { params: query, headers: getHeaders() })
                                    .then((response) => {
                                        if (response.data?.data) {
                                            setDados(response.data.data);
                                            setCarregando(false);
                                        } else {
                                            setDados(null);
                                            NotificationManager.info(strings.nenhumRegistroEncontrado);
                                        }
                                    })
                                    .catch(err => console.log(err))
                                    .finally(() => {
                                        setSubmitting(false);
                                        setCarregando(false);
                                    });
                            }}
                        >
                            {
                                ({
                                    isSubmitting,
                                    handleSubmit,
                                    isValid,
                                    resetForm
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSubmit();
                                            }
                                        }}>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttNotification severity="info">
                                                        {strings.infoPesquisaNomeCadsus}
                                                    </SttNotification>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={8}>
                                                    <FastField name={NOME}>
                                                        {({
                                                            field,
                                                            meta
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                                                label={strings.nome}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={4}>
                                                    <Field name={DATA_NASCIMENTO}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            let erroAtual = meta.error;
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataNascimento}
                                                                    inputProps={{
                                                                        name: name,
                                                                        inputMode: 'numeric'
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        if (error && error !== erroAtual) {
                                                                            setFieldError(DATA_NASCIMENTO, error);
                                                                        }
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_NASCIMENTO, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => {
                                                                        setFieldValue(DATA_NASCIMENTO, date, true);
                                                                    }}
                                                                    onClose={() => setFieldTouched(DATA_NASCIMENTO, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={4}>
                                                    <FastField name={CPF}>
                                                        {({
                                                            field,
                                                            meta,
                                                        }) => (
                                                            <SttMaskedInput
                                                                {...field}
                                                                inputMode="numeric"
                                                                mask="cpf"
                                                                label={strings.cpf}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={4}>
                                                    <FastField name={CNS}>
                                                        {({
                                                            field,
                                                            meta,
                                                        }) => (
                                                            <SttMaskedInput
                                                                {...field}
                                                                inputMode="numeric"
                                                                mask="cns"
                                                                label={strings.cns}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={4}>
                                                    <div className={classes.buttonWrapper}>
                                                        <SttButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            nomarginleft="true"
                                                            disabled={isSubmitting || !isValid}
                                                        >
                                                            {strings.buscar}
                                                        </SttButton>

                                                        <SttButton variant="outlined"
                                                            onClick={() => {
                                                                resetForm();
                                                                setDados(null);
                                                            }}
                                                            color="secondary"
                                                        >
                                                            {strings.limpar}
                                                        </SttButton>
                                                    </div>
                                                </SttGrid>
                                            </SttGrid>
                                        </form>
                                    )
                                }
                            }
                        </Formik>
                        {
                            dados?.length > 0 &&
                            dados.map((paciente, idx) => (
                                <div key={idx}>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttCard variant="outlined" className={classes.card}>
                                                <SttCardContent>
                                                    {paciente.nome && <SttTextItem key="1" title={strings.nome} content={paciente.nome} />}
                                                    {paciente.cpf && <SttTextItem key="2" title={strings.cpf} content={formatterCPF.apply(paciente.cpf)} />}
                                                    {paciente.cns && <SttTextItem key="3" title={strings.cns} content={formatterCNS.apply(paciente.cns)} />}
                                                    {paciente.data_nascimento && <SttTextItem key="4" title={strings.dataNascimento} content={(<Moment format="DD/MM/YYYY">{paciente.data_nascimento}</Moment>)} />}
                                                    {paciente.sigla_sexo && <SttTextItem key="5" title={strings.genero} content={siglaSexo2Descricao(paciente.sigla_sexo)} />}
                                                    {paciente.nome_mae && <SttTextItem key="6" title={strings.nomeMae} content={paciente.nome_mae} />}
                                                    {paciente.profissao && <SttTextItem key="7" title={strings.profissao} content={paciente.profissao} />}
                                                    {paciente.cidade_uf && <SttTextItem key="8" title={strings.cidadeUf} content={paciente.cidade_uf} />}
                                                    <TableContainer className={classes.tableWrapper} component={Paper}>
                                                        <SttTable>
                                                            <SttTableHead>
                                                                <SttTableRow>
                                                                    <SttHidden smDown>
                                                                        <SttTableCell width="60%">
                                                                            {strings.instituicao}
                                                                        </SttTableCell>
                                                                        <SttTableCell width="15%">
                                                                            {strings.cnes}
                                                                        </SttTableCell>
                                                                        <SttTableCell width="25%">
                                                                            {strings.cidadeUf}
                                                                        </SttTableCell>
                                                                    </SttHidden>
                                                                    <SttHidden mdUp>
                                                                        <SttTableCell width="100%">{strings.dadosCNES}</SttTableCell>
                                                                    </SttHidden>
                                                                </SttTableRow>
                                                            </SttTableHead>
                                                            <SttTableBody>
                                                                {
                                                                    paciente.instituicoes && paciente.instituicoes.map((row, index) => (
                                                                        <SttTableRow key={index}>
                                                                            <SttHidden smDown>
                                                                                <SttTableCell>{row.nome_instituicao}</SttTableCell>
                                                                                <SttTableCell>{row.cnes_instituicao}</SttTableCell>
                                                                                <SttTableCell>{`${row.nome_cidade_instituicao}/${row.sigla_estado_instituicao}`}</SttTableCell>
                                                                            </SttHidden>
                                                                            <SttHidden mdUp>
                                                                                <SttTableCell width="100%">
                                                                                    <SttText size="small">
                                                                                        <b>{strings.instituicao}:</b> {row.nome_instituicao}
                                                                                    </SttText>
                                                                                    <SttText size="small">
                                                                                        <b>{strings.cnes}:</b> {row.cnes_instituicao}
                                                                                    </SttText>
                                                                                    <SttText size="small">
                                                                                        <b>{strings.cidadeUf}</b> {`${row.nome_cidade_instituicao}/${row.sigla_estado_instituicao}`}
                                                                                    </SttText>
                                                                                </SttTableCell>
                                                                            </SttHidden>
                                                                        </SttTableRow>
                                                                    ))
                                                                    ||
                                                                    <SttTableRow>
                                                                        <SttTableCell colSpan={3} align="center">{strings.nenhumRegistroEncontrado}</SttTableCell>
                                                                    </SttTableRow>
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </TableContainer>
                                                </SttCardContent>
                                            </SttCard>
                                        </SttGrid>
                                    </SttGrid>
                                </div>
                            ))
                        }
                        <SttLoading
                            open={carregando}
                            text={strings.carregando}
                        />
                    </SttBox>
                    <SttBox className={classes.footer}>
                        <SttButton variant="contained"
                            color="secondary"
                            onClick={() => setOpen(false)}>
                            {strings.fechar}
                        </SttButton>
                    </SttBox>
                </SttBox>
            </Draggable>
        </div >
        ||
        ''
    )
}

export default CnesCadsus;