import solicitacaoCadastroReducer from './solicitacao-cadastro';
import modalReducer from './modal';
import pesquisaFuncionarioReducer from './reducers/pesquisa/funcionario';
import pesquisaEquipeReducer from './reducers/pesquisa/equipe';
import cadastroFuncionarioReducer from './reducers/cadastro/funcionario';
import cadastroPacsReducer from './reducers/cadastro/pacs';
import cadastroPerfilReducer from './reducers/cadastro/perfil';
import cadastroPontoReducer from './reducers/cadastro/pontos';
import cadastroEquipeReducer from './reducers/cadastro/equipes';
import cadastroRedeReducer from './reducers/cadastro/rede';
import cadastroPermissaoReducer from './reducers/cadastro/permissao';
import cadastroMotivoInvalidacaoExameReducer from './reducers/cadastro/invalidacao-exame';
import cadastroMateriaisSuporteReducer from './reducers/cadastro/materiais-suporte';
import cadastroInstituicaoReducer from './reducers/cadastro/instituicao';
import pesquisaInstituicaoReducer from './reducers/pesquisa/instituicao';
import pesquisaPerfilReducer from './reducers/pesquisa/perfil';
import pesquisaRedeReducer from './reducers/pesquisa/rede';
import pesquisaPontosReducer from './reducers/pesquisa/pontos';
import pesquisaLogFuncionarioReducer from './reducers/pesquisa/log-funcionario/index';
import pesquisaLogUsuarioReducer from './reducers/pesquisa/log-usuario/index';
import cadastroPacienteReducer from './reducers/cadastro/paciente';
import pesquisaPacienteReducer from './reducers/pesquisa/paciente';
import pesquisaPacsReducer from './reducers/pesquisa/pacs';
import pesquisaMensagensReducer from './reducers/pesquisa/mensagens';
import pesquisaMateriaisSuporteReducer from './reducers/pesquisa/materiais-suporte';
import cadastroMensagensReducer from './reducers/cadastro/mensagens';
import alertaReducer from './reducers/alertaReducer';
import triagemAutocadastroReducer from './reducers/cadastro/triagem';
import pacienteReducer from './reducers/cadastro/pacienteReducer';
import pesquisaInvalidacaoExameReducer from './reducers/pesquisa/invalidacao-exame';
import triagemAcessoInstituicaoReducer from './reducers/pesquisa/acesso-instituicao';

import { combineReducers } from 'redux';
import TYPES from './types';

const INITIAL_STATE = {
    isAuthenticated: false,
    message: '',
    user: {
        nome: '',
        email: '',
        foto: '',
    },
};

const index = (state, action) => {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated,
            };
        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user,
            };
        case TYPES.LOGOUT:
            return INITIAL_STATE;
        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message,
            };
        default:
            return state;
    }
};

export const Reducers = combineReducers({
    index,
    solicitacaoCadastroReducer,
    modalReducer,
    alertaReducer,
    pacienteReducer,
    pesquisaFuncionarioReducer,
    pesquisaPerfilReducer,
    pesquisaPontosReducer,
    pesquisaEquipeReducer,
    pesquisaMensagensReducer,
    pesquisaMateriaisSuporteReducer,
    cadastroFuncionarioReducer,
    cadastroPacsReducer,
    cadastroPerfilReducer,
    cadastroMateriaisSuporteReducer,
    cadastroPontoReducer,
    cadastroPermissaoReducer,
    cadastroEquipeReducer,
    triagemAutocadastroReducer,
    cadastroRedeReducer,
    cadastroMensagensReducer,
    pesquisaRedeReducer,
    pesquisaPacsReducer,
    pesquisaPacienteReducer,
    cadastroPacienteReducer,
    pesquisaInvalidacaoExameReducer,
    cadastroMotivoInvalidacaoExameReducer,
    pesquisaInstituicaoReducer,
    cadastroInstituicaoReducer,
    pesquisaLogFuncionarioReducer,
    pesquisaLogUsuarioReducer,
    triagemAcessoInstituicaoReducer
});
