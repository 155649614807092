'use strict';

const { DataError } = require('../erros');
const LogFuncionario = require('./log-funcionario');
const { LOG } = require('../constantes');
const xmlJs = require('xml-js');
const path = require('path');
const moment = require('moment');

const Laudo = {
    /**
     * Atualiza o XML do laudo dos exames informados
     * com dados alterados do paciente
     *
     * @param {array} exame
     */
    atualizarDadosPaciente: async (dbClient, transacao, exames, dados, idFuncionario) => {
        // Busca os laudos do paciente sem o identificado da transação
        // fazendo com que laudos emitidos enquanto a alteração dos dados
        // está em andamento também sejam alterados.
        const laudos = await dbClient.select('id', 'data', 'xml').from('stt.laudo').whereIn('id_exame', exames);

        for (const laudo of laudos) {
            try {
                const idLaudo = laudo['id'];
                let dadosAlterados = {};
                let xml = xmlJs.xml2js(laudo['xml']);
                const dadosPac = xml.elements[0].elements.find((e) => e.name === 'patient');
                let id = dadosPac.elements.find((e) => e.name === 'id');
                id.elements[0].text = dados.id;
                if (dados.nome) {
                    dadosAlterados.nome = dados.nome;
                    // Separa o texto por qualquer quantidade de espaços em branco
                    const nomeArr = dados.nome.trim().split(/[ ]+/);
                    let nomeEst = {};
                    let nome = dadosPac.elements.find((e) => e.name === 'name');

                    if (nomeArr.length > 1) {
                        nomeEst.last = nomeArr.pop();
                        nomeEst.first = nomeArr.join(' ');
                    } else {
                        nomeEst.first = dados.nome;
                        nomeEst.last = '';
                    }

                    const last = nome.elements.find((e) => e.name === 'last');
                    last.elements = [{ type: 'text', text: nomeEst.last }];

                    const first = nome.elements.find((e) => e.name === 'first');
                    first.elements = [{ type: 'text', text: nomeEst.first }];
                }
                if (dados.data_nascimento) {
                    dadosAlterados.data_nascimento = dados.data_nascimento;
                    let dataNasc = dadosPac.elements.find((e) => e.name === 'birthday');
                    dataNasc.elements.find((e) => e.name === 'date').elements[0].text = dados.data_nascimento;

                    let idade = dadosPac.elements.find((e) => e.name === 'age');
                    if (idade) {
                        const age = moment(laudo['data']).diff(moment(dados.data_nascimento), 'years');
                        idade.elements = [{ type: 'text', text: age }];
                    }
                }
                if (dados.sexo) {
                    dadosAlterados.sexo = dados.sexo;
                    let sexo = dadosPac.elements.find((e) => e.name === 'sex');
                    if (sexo) {
                        sexo.elements[0].text = dados.sexo.sigla;
                    }
                }

                const xmlModificado = xmlJs.js2xml(xml);

                await dbClient('stt.laudo').update({ xml: xmlModificado }).where('id', '=', idLaudo).transacting(transacao);

                await LogFuncionario.inserir(dbClient, transacao, {
                    idFuncionario: idFuncionario,
                    evento: `O funcionário de ID ${idFuncionario} corrigiu os seguintes dados do paciente ${dados.tipoPaciente} de ID ${dados.id} no laudo de ID ${idLaudo}: ${JSON.stringify(
                        dadosAlterados
                    )}`,
                    url: null,
                    arquivo: path.basename(__filename),
                    codigo: LOG.PACIENTE_VINCULADO_EXAME,
                    prioridade: LOG.INFO
                });
            } catch (error) {
                console.log(error);
                throw new DataError('Não foi possível validar a estrutura do(s) laudo(s) do(s) exame(s) do paciente. Entre em contato com os administradores.');
            }
        }
    }
};

module.exports = Laudo;
