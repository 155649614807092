import React, { useEffect, lazy, Suspense, memo, useContext } from 'react';
import {
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { SttTabs } from '@stt-componentes/core-v2';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { temPermissaoRBAC } from '@src/security/acl';
import { PERMISSOES } from '@src/common/Constants';
import usuario from '@src/signals/usuario';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1)
    },
    divWrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));

const MateriaisSuporteSuspense = memo((props) => {
    const MateriaisSuporte = lazy(() => import('../../../componentes/interno/pesquisa/materiais-suporte'));

    return (
        <div className={props.className}>
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MateriaisSuporte {...props} />
        </Suspense>
        </div>
    )
});

const IndexSuporte = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useEffect(() => {
        let dadosAbas = [];

        if (temPermissaoRBAC(usuario.value, PERMISSOES.PESQUISA_MATERIAIS_SUPORTE)) {
            dadosAbas.push({
                titulo: strings.materiaisSuporte,
                conteudo: MateriaisSuporteSuspense,
                key: 'MateriaisSuporteSuspense',
                permanente: true,
                className: classes.divWrapper
            });
        }

        abas.value = dadosAbas;
    }, []);

    return (
        <SttTabs abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(IndexSuporte);