import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setUser as setUserAction } from '@redux/actions';
import { setOpen as setOpenAction } from '@redux/actions/alerta';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Menu from '@componentes/interno/menu';
import { SttCircularProgress, SttAlerta } from '@stt-componentes/core';
// import PesquisaMensagens from './pesquisa/mensagens';
// import TriagemAcessoInstituicao from './pesquisa/acesso-instituicao';
// import CadastroMensagens from './cadastro/mensagens';
import IndexSuporte from './suporte';
import IndexAdministrativo from './administrativo';
import IndexComunicacao from './comunicacao';
import IndexTelediagnostico from './telediagnostico';
import { PERMISSOES } from '@common/Constants';
import Erro401 from '../erro/401';
import ProtectedRoute from './protectedRoute';
import { SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import usuario from '@src/signals/usuario';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: theme.spacing(4),
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px',
    },
}));

const Interno = ({ setUser, setOpen, parametrosAlerta }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div>
            {
                usuario.value.autenticado ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Routes>
                            <Route path="/nao-autorizado" element={<Erro401 />} />

                            <Route
                                path='*'
                                exact
                                element={
                                    <ProtectedRoute component={IndexSuporte}
                                        permissao={[
                                            PERMISSOES.PESQUISA_FUNCIONARIO,
                                            PERMISSOES.TRIAGEM_CADASTRO
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path="/admin"
                                exact
                                element={
                                    <ProtectedRoute component={IndexAdministrativo}
                                        permissao={[
                                            PERMISSOES.PESQUISAR_INST,
                                            PERMISSOES.PESQUISA_EQUIPES,
                                            PERMISSOES.PESQUISA_PERFIL,
                                            PERMISSOES.PERMISSOES,
                                            PERMISSOES.PESQUISAR_PACIENTE,
                                            PERMISSOES.PESQUISAR_PACS,
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path="/comunicacao"
                                exact
                                element={
                                    <ProtectedRoute component={IndexComunicacao}
                                        permissao={PERMISSOES.PESQUISA_MATERIAIS_SUPORTE}
                                    />
                                }
                            />

                            <Route
                                path="/telediagnostico"
                                exact
                                element={
                                    <ProtectedRoute component={IndexTelediagnostico}
                                        permissao={[
                                            PERMISSOES.CADASTRO_CIDADE_REF,
                                            PERMISSOES.PESQUISA_MOTIVOS_INVALIDACAO,
                                            PERMISSOES.PESQUISA_PONTO,
                                            PERMISSOES.PESQUISA_REDE_TELEMEDICINA,
                                        ]}
                                    />
                                }
                            />

                        {/*
                            <Route path="/pesquisa/mensagens" element={<ProtectedRoute component={PesquisaMensagens} permissao={PERMISSOES.PESQUISA_REDE_TELEMEDICINA} />} />
                            <Route path="/cadastro/mensagens" element={<ProtectedRoute component={CadastroMensagens} permissao={PERMISSOES.CADASTRO_MENSAGEM} />} />
                            <Route path='/pesquisa/acesso-instituicao' element={<ProtectedRoute component={TriagemAcessoInstituicao} permissao={PERMISSOES.PESQUISA_TRIAGEM_ACESSO_INSTITUICAO} />} />
                        */}

                        </Routes>
                        <SttAlerta
                            open={parametrosAlerta.open}
                            title={parametrosAlerta.titulo}
                            message={parametrosAlerta.mensagem}
                            type={parametrosAlerta.tipo}
                            onClose={() => {
                                if (parametrosAlerta.onClose) {
                                    parametrosAlerta.onClose();
                                    return;
                                }
                                setOpen(false);
                            }}
                            options={parametrosAlerta.opcoes || []}
                        />
                    </div>
                    :
                    <>
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                        <SttCacheManager
                            config={global.gConfig}
                            onSync={(loaded) => {
                                const usuarioCache = Cache.getUserInfo();
                                usuario.value = { ...Cache.getUserInfo(), autenticado: loaded };

                                setUser(usuarioCache);
                                navigate(location);
                            }}
                        />
                    </>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        parametrosAlerta: state.alertaReducer.parametros,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUserAction(user)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);