import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Home from './paginas';
import { Provider } from 'react-redux';
import { Store } from './store';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { MsalProvider } from "@azure/msal-react";
import { SttTranslateHook } from '@stt-componentes/core';
import { SttThemeProvider } from '@stt-componentes/core-v2';

const Conteudo = () => {
	return <div>
		<NotificationContainer />
		<Provider store={Store}>
			<SttThemeProvider config={global.gConfig}>
				<SttTranslateHook.I18nContextProvider modulo={'administrativo'}>
					<Home />
				</SttTranslateHook.I18nContextProvider>
			</SttThemeProvider>
		</Provider>
	</div>
}

class App extends Component {
	render() {
		const { pca } = this.props;
		return (
			pca ? (
				<MsalProvider instance={pca}>
					< Conteudo />
				</MsalProvider >
			) : (
				<Conteudo />
			)
		);
	}

	getChildContext() {
		return {
			modulo: this.props.modulo,
			currentLanguage: this.props.currentLanguage
		};
	}
}

App.childContextTypes = {
	currentLanguage: PropTypes.string.isRequired,
	modulo: PropTypes.string.isRequired
};

export default App;