module.exports = {
    acesso_externo: 'Acesso Externo',
    aetitle: 'AE Title',
    ativo: 'Ativo',
    automacao: 'Automação',
    bridge_aetitle: 'Bridge AE Title',
    bridges: 'Bridges',
    dataAcao: 'Data da ação',
    dataPrimeiroExame: 'Data do primeiro exame',
    dataUltimaAcao: 'Data da última ação',
    identificador: 'Identificador',
    integracaoExames: 'Integrações de Exames',
    ip: 'IP',
    mensagem: 'Mensagem',
    modalidade: 'Modalidade',
    nome: 'Nome',
    notificacao: 'Notificação',
    numeroExame: 'Número do exame',
    pacs: 'PACS',
    pacs_aetitle: 'PACS AE Title',
    porta: 'Porta',
    processado: 'Processado?',
    status: 'Último status',
    stt:'STT',
    tituloVisualizacao: 'Visualização de histórico',
    url: 'URL',
    urlPacs: 'URL do PACS',
};
