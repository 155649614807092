import { VINCULO_PERFIL_ESTADUAL, VINCULO_PERFIL_INSTITUICOES } from "@src/common/Constants";

const temPerfil = (usuario, perfil) => {
    return usuario.perfisRBAC.some(p => {
        return p.identificador === perfil;
    });
};

const temPermissaoRBAC = (usuario, permissao) => {
    if (!usuario) {
        return false;
    }
    if (!usuario.permissoesRBAC || !permissao) {
        return false;
    }
    if (Array.isArray(permissao)) {
        return permissao.some(p => usuario.permissoesRBAC.includes(p));
    }

    return usuario.permissoesRBAC.includes(permissao);
};

const temVinculoEstadual = (usuario) => {
    return usuario.perfisRBAC.some(p => p.vinculo === VINCULO_PERFIL_ESTADUAL);
};

const pegarEstadosVinculoPerfil = (usuario) => {
    let estadosPerfil = [];
    usuario.perfisRBAC.forEach(p => {
        if (p.vinculo === VINCULO_PERFIL_ESTADUAL && p.estados) {
            let estados = p.estados.map(e => e.id);
            estadosPerfil = estadosPerfil.concat(estados);
        }
    });
    return estadosPerfil;
}

const pegarInstituicoesVinculoPerfio = (usuario) => {
    let instituicoesPerfil = [];
    usuario.perfisRBAC.forEach(p => {
        if (p.vinculo === VINCULO_PERFIL_INSTITUICOES && p.instituicoes) {
            let instituicoes = p.instituicoes.map(e => e.id);
            instituicoesPerfil = instituicoesPerfil.concat(instituicoes);
        }
    });
    return instituicoesPerfil;
}

export { temPerfil, temPermissaoRBAC, temVinculoEstadual, pegarEstadosVinculoPerfil, pegarInstituicoesVinculoPerfio };

