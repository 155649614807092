const stringsComuns = require('./comum');

module.exports = {
    acompanharFacebook: 'Acompanhar facebook',
    aguardandoTriagem: 'Aguardando Triagem',
    alertaAlteracao: 'Apenas dados cujo respectivo "checkbox" esteja marcado serão alterados.',
    alteracaoFuncionario: 'Alteração de Funcionário',
    alteracaoMateriaisSuporte: 'Alteração de Materiais de Apoio',
    alteracaoPacs: 'Alteração de PACS',
    alteracaoPerfil: 'Alteração de Perfil',
    alteracaoPonto: 'Alteração de Ponto',
    alteracaoRede: 'Alteração de Rede de Telemedicina',
    anexos: 'Anexo',
    aprovacaoCadastro: 'Aprovação de cadastro realizada com sucesso',
    aprovarAutoCadastro: 'Aprovar auto-cadastro',
    ativar: 'Ativar',
    bairro: 'Bairro',
    bloquear: 'Bloquear',
    Cabecalho: {},
    cadastroAprovado: 'Cadastro aprovado',
    cadastroCidadeRef: 'Cadastro de Cidade Referência',
    cadastroEquipe: 'Cadastro de Equipe de Saúde',
    CadastroEquipes: {},
    cadastroFuncionario: 'Cadastro de funcionários',
    CadastroFuncionarioEquipe: {
        confirmacaoDelecaoEquipe: 'Você tem certeza que deseja excluir essa equipe de saúde?'
    },
    CadastroFuncionarioModalidade: {
        tipoVinculo: 'Tipo de vínculo'
    },
    CadastroFuncionarioPerfis: {
        confirmacaoDelecaoPerfil: 'Você realmente deseja desvincular este perfil do usuário?'
    },
    CadastroFuncionarioUsuario: {
        gerarNovaSenha: 'Cadastrar nova senha',
        gerarNovaSenhaAleatoria: 'Gerar nova senha aleatória',
        mensagemGerarNovaSenhaAleatoria: 'Você tem certeza que deseja gerar uma nova senha e enviar via email para este usuário?'
    },
    CadastroInstituicao: {
        bairro: 'Bairro',
        cep: 'CEP',
        cnes: 'CNES',
        cnpj: 'CNPJ',
        codinome: 'Codinome',
        confirmacaoAlteracaoInstituicao: 'Deseja enviar os dados preenchidos para alteração?',
        confirmacaoCadastroInstituicao: 'Deseja enviar os dados preenchidos para cadastro?',
        confirmacaoDelecaoInstituicao: 'Você tem certeza que deseja excluir essa instituição? Quaisquer equipes de saúde associadas a ela também serão excluídas',
        confirmacaoDelecaoSetor: 'Você tem certeza de que deseja excluir este setor da instituição?',
        consultorioVirtualHabilitado: 'Consultório virtual habilitado?',
        logotipo: 'Logotipo',
        logotipoGrande: 'Você escolheu uma logotipo de resolução muito grande. Por favor, selecione outra imagem com resolução inferior a 400px de largura por 400px de altura.',
        logradouro: 'Logradouro',
        nome: 'Nome',
        remover: 'Remover',
        setorInstituicao: 'Setores da instituição',
        setorInstituicaoDuplicado: 'Já existe um setor desta instituição com esta descrição',
        tipoEstabelecimento: 'Tipo de estabelecimento',
        urlServidor: 'URL do servidor Web-RTC'
    },
    cadastroMateriaisSuporte: 'Cadastro de Materiais de Apoio',
    CadastroMateriaisSuporte: {
        anexos: 'Anexo',
        confirmacaoDelecao: 'Você realmente deseja excluir este perfil?',
        confirmacaoDelecaoModulo: 'Você realmente deseja excluir este módulo?',
        formatoArquivoInvalidoMateriaisSuporte: 'Formato de arquivo inválido. Somente PDF é aceito.',
        listamodulos: 'Lista de Módulos',
        listaperfis: 'Lista de Perfis',
        perfis: 'Perfis',
        selecioneTipoModulo: 'Por favor, selecione um tipo de módulo.'
    },
    CadastroMensagemGeral: {
        area: 'Área de interesse',
        assunto: 'Assunto',
        conteudo: 'Conteúdo',
        mensagemAtiva: 'A mensagem está ativa?'
    },
    CadastroMensagemOutros: {
        macrorregiao: 'Macrorregião'
    },
    CadastroMensagens: {
        confirmacaoAlteracaoMensagens: 'Deseja enviar os dados preenchidos para alteração?',
        confirmacaoCadastroMensagens: 'Deseja enviar os dados preenchidos para cadastro?',
        outrasInformacoes: 'Outras informações',
        tituloPagina: 'Cadastro de mensagem',
        tituloPaginaAlteracao: 'Alteração de mensagem'
    },
    cadastroMotivoInvalidacao: 'Cadastro de Motivos de Invalidação',
    cadastroPacs: 'Cadastro de PACS',
    cadastroPendente: 'Cadastro pendente',
    cadastroPendente: 'Cadastro pendente',
    cadastroPerfil: 'Cadastro de Perfis',
    cadastroPermissoes: 'Cadastro de Permissões',
    cadastroPonto: 'Cadastro de Ponto',
    CadastroPontoDadosAtividade: {
        atividadesAdicionadas: 'Atividades adicionadas',
        confirmacaoDelecao: 'Você realmente deseja excluir esta atividade?',
        descricaoAtividade: 'Descrição da atividade'
    },
    CadastroPontoDadosContato: {
        confirmacaoDelecao: 'Você realmente deseja excluir este contato?',
        contatosAdicionados: 'Contatos adicionados',
        informeContato: 'Por favor, informe um contato válido.',
        selecioneTipoContato: 'Por favor, selecione um tipo de contato.',
        tipoContato: 'Tipo de contato'
    },
    CadastroPontoDadosGerais: {
        dataInclusao: 'Data de inclusão'
    },
    CadastroPontoDadosTipo: {
        aetitle: 'Aetitle',
        bridge: 'Bridge',
        confirmacaoSenha: 'Confirmação de senha'
    },
    CadastroPontoDadosVinculo: {
        secretario: 'Secretário de saúde'
    },
    CadastroPontoExamesVinculados: {
        exames30dias: 'Últimos 30 dias',
        exames90dias: 'Últimos 90 dias',
        exames180dias: 'Últimos 180 dias',
        examesVinculos: 'Exames vinculados ao ponto'
    },
    CadastroPontoMedicosVinculados: {
        solicitantes: 'Médicos Solicitantes vinculados a este ponto'
    },
    CadastroPontoTecnicosVinculados: {
        tecnicos: 'Técnicos vinculados a este ponto'
    },
    CadastroPontoVisualizadoresVinculados: {
        visualizadores: 'Visualizadores vinculados a este ponto'
    },
    cadastroRede: 'Cadastro de Rede de Telemedicina',
    cadastroRejeitado: 'Cadastro rejeitado',
    categoriaStt1: 'Categoria',
    categoriaStt2: 'Categoria STT2',
    Checkin: {
        ano: 'Ano de nascimento',
        dadosDigitadosInvalidos: 'O(s) dado(s) digitados para validação estão incorretos. Por favor, digite as informações corretas para continuar.',
        dia: 'Dia de nascimento',
        erroGenericoValidacao: 'Não foi possível validar seus dados. Por favor tente novamente mais tarde.',
        erroValidacao: 'Erro de validação!',
        mes: 'Mês de nascimento',
        validar: 'Validar',
        validarDados: 'Para validarmos seu cadastro, por favor digite os dados solicitados abaixo.'
    },
    cidadeReferencia: 'Cidade referência',
    cidadesReferencias: 'Cidades referenciadas',
    classificada: 'Classificada',
    cnes: 'CNES',
    cnesCadsus: 'CNES/CADSUS',
    cnpj: 'CNPJ',
    codigoIne10Caracteres: 'O código INE deve conter exatamente 10 caracteres numéricos',
    codinome: 'Codinome',
    comunicacao: 'Comunicação',
    confirmacaoAlteracao: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoEquipes: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoFuncionario: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoInstituicao: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoMateriaisSuporte: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoPaciente: 'Confirma alteração do $ do paciente?',
    confirmacaoAlteracaoPacs: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoPerfil: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoAlteracaoRede: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastro: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroEquipes: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroFuncionario: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroInstituicao: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroMateriaisSuporte: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroMotivosInvalidacaoExame: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroPacs: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroPerfil: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoCadastroPermissao: 'Você tem certeza que deseja cadastrar as permissões para o perfil escolhido?',
    confirmacaoCadastroRede: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoDelecao: 'Você realmente deseja excluir este perfil?',
    confirmacaoDelecaoInstituicao: 'Você tem certeza que deseja excluir essa instituição? Quaisquer equipes de saúde associadas a ela também serão excluídas',
    confirmacaoDelecaoModulo: 'Você realmente deseja excluir este módulo?',
    confirmacaoDelecaoSetor: 'Você tem certeza de que deseja excluir este setor da instituição?',
    confirmacaoDeslogarUsuario: 'Deseja desconectar o funcionário de todos os dispositivos?',
    confirmacaoExcluirPerfil: 'Deseja mesmo excluir esse perfil?',
    confirmacaoRejeitarSolicitacao: 'Tem certeza que deseja rejeitar essa solicitação de auto-cadastro?',
    confirmacaoTornarPendente: 'Tem certeza que deseja tornar esta solicitação pendente?',
    confirmarOperacaoPonto: 'Você confirma esta operação sobre o ponto de telediagnóstico?',
    consultorioVirtualHabilitado: 'Consultório virtual habilitado?',
    consultorioVirtualHabilitado: 'Consultório virtual habilitado?',
    dadosAtividade: 'Atividades',
    dadosCadastrais: 'Dados cadastrais',
    dadosCADSUS: 'Dados no CADSUS',
    dadosCNES: 'Dados no CNES',
    dadosContato: 'Dados de contato',
    DadosContato: {},
    DadosContatoTriagemAutoCadastro: {},
    dadosExameUsuarios: 'Exames / Usuários vinculados',
    dadosGerais: 'Dados gerais',
    DadosGerais: {
        atividade: stringsComuns.atividade,
        dadosEncontradosCadsus: 'Foram encontrados dados do funcionário no CADSUS pelo CPF informado. Você deseja continuar o cadastro a partir desses dados?',
        divulgacaoAutorizada: 'Divulgação Autorizada',
        funcionarioJaExiste: 'Já existe um funcionário cadastrado para o CPF informado. Por favor, utilize o menu de pesquisa para realizar visualização ou alteração dos dados.',
        verificarCpf: 'Verificar CPF'
    },
    DadosGeraisTriagemAutoCadastro: {},
    dadosProfissionais: 'Dados profissionais',
    numeroRqe: 'Número RQE',
    rqe: 'RQE - Registro de Qualificação de Especialidade',
    rqeJaAdicionado: 'RQE já adicionado',
    DadosProfissionaisTriagemAutoCadastro: {},
    dadosTipo: 'Tipo de ponto',
    dadosVinculo: 'Vinculos',
    dataCriacaoAte: 'Criação - até',
    dataCriacaoDe: 'Criação - de',
    dataHoraSolicitacao: 'Data e hora da solicitação',
    dataProcedimento: 'Data/Hora do procedimento',
    desativar: 'Desativar',
    desbloquear: 'Desbloquear',
    desejaAprovarAutoCadastro: 'Deseja aprovar este auto-cadastro?',
    desejaAprovarCadastro: 'Deseja aprovar este cadastro e salvar os dados de alteração deste funcionário?',
    deslogarFuncionario: 'Deslogar funcionário',
    DetalhesFuncionario: {
        dadosGerais: 'Dados gerais',
        visualizacaoFuncionario: 'Visualização de funcionário'
    },
    equipes: 'Equipes',
    erroRejeitarCadastro: 'Ocorreu um erro ao rejeitar a solicitação. Tente novamente mais tarde',
    evento: 'Evento',
    Externo: {},
    formatoArquivoInvalidoMateriaisSuporte: 'Formato de arquivo inválido. Somente PDF é aceito.',
    FormPesquisaMensagens: {
        area: 'Área de interesse',
        assunto: 'Assunto',
        cidade: 'Cidade',
        equipe: 'Equipe',
        instituicao: 'Instituição',
        macrorregiao: 'Macrorregião',
        situacao: 'Situação'
    },
    FormularioTriagemAutoCadastro: {
        verificandoLogin: 'Verificando login'
    },
    historicoProcedimentos: 'Histórico de procedimentos',
    ine: 'Código INE',
    infoPesquisaNomeCadsus: 'Ao pesquisar por nome, informe também a data de nascimento.',
    instituicaoDiferente: 'Você deve escolher uma instituição diferente da que está vinculada ao ponto original',
    instrucaoAprovacaoAutoCadastro: `<span>- Cadastro aguardando triagem ou pendente;</span>
        <span>- Utilize o botão "Aprovar" para aprovar este auto-cadastro e salvar as alterações do usuário;</span>
        <span>- Você também pode utilizar o botão "Salvar" para alterar o usuário sem modificar a situação da solicitação de auto-cadastro.</span>`,
    irParaCadastro: 'Ir para cadastro',
    labelOperacaoPonto: operacao => `Motivo para ${operacao} o ponto:`,
    legendaInstituicaoVinculoCadsus: 'Vínculos com instituição de origem DATASUS',
    legendaInstituicaoVinculoStt: 'Instituições informadas manualmente',
    liberacaoInstitucional: 'Liberação institucional',
    listamodulos: 'Lista de Módulos',
    listaperfis: 'Lista de Perfis',
    logFuncionario: 'Log Funcionário',
    loginCaracteresAlfanumericos: 'Somente caracteres alfanuméricos são aceitos',
    logotipo: 'Logotipo',
    logotipoGrande: 'Você escolheu uma logotipo de resolução muito grande. Por favor, selecione outra imagem com resolução inferior a 400px de largura por 400px de altura.',
    logradouro: 'Logradouro',
    logUsuario: 'Log Usuário',
    mensagemAtualizarCadastroPendente: 'Atualizando situação da solicitação',
    mensagemConfirmacaoDesselecao: 'Desmarcando essa opção, todas as permissões desse perfil para o módulo em questão serão desmarcadas. Você deseja prosseguir?',
    ModalAdicionarEquipe: {
        adicionarEquipe: 'Adicionar equipe'
    },
    ModalAdicionarModalidade: {
        adicionarModalidade: 'Adicionar modalidade',
        confirmarExclusao: 'Você confirma a exclusão desta modalidade?'
    },
    ModalAdicionarSetor: {
        adicionarSetor: 'Adicionar setor'
    },
    ModalAdicionarUsuario: {
        adicionarUsuario: 'Adicionar usuário',
        alterarUsuario: 'Alterar usuário'
    },
    ModalAlteracaoSenha: {
        alteracaoSenha: 'Alteração de senha',
        confirmacaoSenha: 'Confirmação de senha',
        novaSenha: 'Nova senha',
        novaSenhaConfirmacaoCpf: 'A senha não pode conter o CPF',
        novaSenhaConfirmacaoDataNascimento: 'A senha não pode conter sua data de nascimento',
        novaSenhaConfirmacaoEmail: 'A senha não pode conter o Email',
        novaSenhaConfirmacaoLogin: 'A senha não pode conter o Login de usuário',
        novaSenhaConfirmacaoNome: 'A senha não pode conter o nome',
        novaSenhaConfirmacaoSenhaIgual: 'A nova senha e a confirmação de senha devem ser iguais',
        senhaAoMenosUmaMaiuscula: 'A senha deve conter pelo menos uma letra maiúscula.',
        senhaAoMenosUmaMinuscula: 'A senha deve conter pelo menos uma letra minúscula.',
        senhaAoMenosUmCaracter: 'A senha deve conter pelo menos um caractere especial.',
        senhaAoMenosUmNumero: 'A senha deve conter pelo menos um número.',
        senhaMinimo9Caracteres: 'A senha deve ter no mínimo 8 caracteres.',
        senhaSemEspacos: 'A senha não pode conter espaços.'
    },
    motivoInvalido: 'O motivo deve ter pelo menos 10 caracteres',
    motivoRecusa: 'Motivo de recusa',
    motivosInvalidacao: 'Motivos de invalidação',
    municipiosAdicionados: 'Municípios adicionados',
    naoClassificada: 'Não classificada',
    naoRespondeu: 'Não respondeu',
    nomeLogin: 'Nome/login',
    numeroConselhoTrabalhoTamanho: 'O número do conselho de trabalho deve conter no máximo 20 caracteres',
    numeroRqe: 'Número RQE',
    pacs: 'PACS',
    perfis: 'Perfis',
    permissoes: 'Permissões',
    pesquisaEquipe: 'Pesquisa de Equipes de Saúde',
    pesquisaFuncionarioNovoCadastro: 'Detectamos que você realizou uma pesquisa por CPF que não obteve nenhum resultado. Deseja começar um novo cadastro a partir desse CPF?',
    pesquisaInstituicao: 'Pesquisa de Instituições',
    pesquisaLogFuncionario: 'Pesquisa de Log de Funcionário',
    pesquisaLogUsuario: 'Pesquisa de Log de Usuário',
    PesquisaLogUsuario: {
        categoria: 'Categoria',
        data: 'Data',
        dataAte: 'Até',
        dataDe: 'Desde',
        evento: 'Evento',
        funcionario: 'Funcionário',
        tituloPagina: 'Pesquisa de Log Usuário',
        url: 'Url'
    },
    pesquisaMateriais: 'Pesquisa de materiais de apoio',
    PesquisaMensagens: {
        area: 'Área de interesse',
        assunto: 'Assunto',
        mensagem: 'Mensagem',
        opcoes: 'Opções',
        situacao: 'Situação',
        tituloPagina: 'Pesquisa de mensagens'
    },
    pesquisaMotivoInvalidacao: 'Pesquisa de Motivos de Invalidação',
    pesquisaPacientes: 'Pesquisa de Pacientes',
    pesquisaPacs: 'Pesquisa de PACS',
    pesquisaPerfil: 'Pesquisa de Perfis',
    pesquisaPontos: 'Pesquisa de Pontos',
    pesquisaRedes: 'Pesquisa de Redes de Telemedicina',
    pesquisaUsuarios: 'Pesquisa de Usuários',
    pontos: 'Pontos',
    possuiConselhoTrabalho: 'Possui conselho de trabalho?',
    profissaoInformada: 'Profissão informada',
    redes: 'Redes',
    rejeitarAutoCadastro: 'Rejeitar auto-cadastro',
    remover: 'Remover',
    rqe: 'RQE - Registro de Qualificação de Especialidade',
    rqeJaAdicionado: 'RQE já adicionado',
    selecioneTipoModulo: 'Por favor, selecione um tipo de módulo.',
    senhaConfirmacaoSenhaIgual: 'A senha e a confirmação de senha devem ser iguais',
    setorInstituicao: 'Setores da instituição',
    setorInstituicaoDuplicado: 'Já existe um setor desta instituição com esta descrição',
    SolicitacaoAviso: {
        solicitacao: 'Solicitação de cadastro',
        solicitacaoPaciente: 'Solicitação de cadastro de paciente',
        texto1: instanciaNome => `Seja bem-vindo(a) ao ${instanciaNome}, o STT! Antes de prosseguir com o seu cadastro, saiba que:`,
        texto1Paciente: 'Preencha corretamente todos os campos do formulário de cadastro.',
        texto2: 'Este cadastro dará acesso aos serviços do STT.',
        texto3: (instanciaNome, basename) => instanciaNome === 'ebserh' || basename === 'ebserh' ? 'Após informar o seu e-mail EBSERH, o seu CPF será conferido e seus dados serão importados do CadSUS. Você deverá preencher os campos solicitados para finalizar sua solicitação de cadastro.' : 'Após informar o seu CPF, os seus dados serão importados do CadSUS. Você deverá preencher os campos solicitados e criar a sua senha de acesso ao STT.',
        texto4: (instanciaNome, basename) => instanciaNome === 'ebserh' || basename === 'ebserh' ? 'Ao concluir o preenchimento dos dados e clicar no botão "Salvar", você estará automaticamente habilitado para fazer o acesso inicial ao sistema. A habilitação aos demais serviços dependerá da validação da nossa equipe.' : 'Ao concluir o preenchimento dos dados e clicar no botão "Salvar", você estará automaticamente habilitado para acessar, exclusivamente, o serviço de Tele-educação. A habilitação aos demais serviços dependerá da validação da nossa equipe.',
        texto4Paciente: (instanciaNome, exibirPesquisa) => `Esse processo garante que suas participações no ${instanciaNome} sejam registradas como atividades de ${!exibirPesquisa ? 'educação permanente' : 'pesquisa'}!`,
        texto5: (instanciaNome, basename) => instanciaNome === 'ebserh' || basename === 'ebserh' ? 'Mais informações serão enviadas para o seu e-mail EBSERH, que deve ser de uso individual.' : 'Mais informações serão enviadas para o e-mail cadastrado, que deve ser um e-mail de uso pessoal e individual.',
        textoAcessoCadsus: 'Seus dados serão buscados no sistema do CADSUS e serão registrados em nosso sistema de forma sigilosa.'
    },
    SolicitacaoBuscaCpf: {
        buscandoDadosCpf: 'Aguarde. Buscando dados do seu CPF...',
        irParaLogin: 'Ir para login',
        mensagemNaoEncontrouPaciente: 'Não encontramos suas informações em nossa base de dados e nem no CADSUS. Por favor, completo seu cadastro manualmente.',
        mensagemUsuarioJaExisteEbserh: 'Este e-mail já possui um usuário cadastrado. Por favor, tente realizar o login para continuar.'
    },
    SolicitacaoComplemento: {
        celularWhatsapp: 'O telefone celular informado é um número de whatsapp?',
        telefoneComercial: 'Telefone comercial'
    },
    SolicitacaoConfirmacaoMensagem: {
        okEntendi: 'Ok, entendi. Quero me cadastrar!'
    },
    SolicitacaoContato: {
        celularWhatsapp: 'O telefone celular informado é um número de whatsapp?',
        telefoneComercial: 'Telefone comercial'
    },
    SolicitacaoDadosProfissionais: {
        numeroConselho: 'Número do conselho',
        possuiConselho: 'Possui conselho de trabalho?',
        tipoConselho: 'Tipo de conselho'
    },
    SolicitacaoEquipeFormIndex: {
        adicionarEquipe: 'Adicionar equipe de saúde'
    },
    SolicitacaoEquipeFormOpcoes: {
        ine: 'Código INE',
        nomeEquipe: 'Nome da equipe de saúde',
        novaEsf: 'Adicionar nova ESF'
    },
    SolicitacaoEquipeIndex: {
        confirmarExclusao: 'Você confirma a exclusão desta equipe de saúde?',
        ine: 'Código INE',
        nenhumaEquipe: 'Nenhuma equipe de saúde adicionada'
    },
    SolicitacaoIndex: {
        alteracaoSenha: 'Alteração de senha',
        avisoPesquisaCadsus: 'Seus dados foram importados do Cartão Nacional do SUS e DataSUS/CNES. Preencha os dados em branco.',
        checkList8Caracteres: 'Pelo menos 8 caracteres.',
        checkListAlfabetico: 'Caractere alfabético.',
        checkListEspecial: 'Caractere especial.',
        checkListMaiuscula: 'Letra maiúscula.',
        checkListMinuscula: 'Letra minúscula.',
        checkListNumerico: 'Caractere numérico.',
        confirmacaoSenha: 'Confirmação de senha',
        confirmacaoSenha: 'Confirmação de senha',
        confirmarAlteracaoSenha: 'Você confirma a alteração da sua senha?',
        confirmarCadastroSenha: 'Você confirma a criação de sua nova senha?',
        crieSuaSenha: 'Crie sua senha',
        dadosProfissionais: 'Dados profissionais',
        erroGeralAutenticacaoExterna: 'Ocorreu um erro na autenticação da sua conta. Por favor, tente novamente mais tarde.',
        erroSalvar: 'Não foi possível salvar os dados da solicitação de cadastro.',
        escolhaSenhaConfirmacaoSenha: 'Preencha sua senha e confirmação de senha',
        escolhaUfCidade: 'Confirme seu estado e município',
        identificacao: 'Identificação',
        instrucaoSenhaCaracterEspecial: 'Ao menos um caractere especial',
        instrucaoSenhaLetraMaiuscula: 'Ao menos uma letra em maiúsculo',
        instrucaoSenhaMinimo8Digitos: 'Ao menos oito dígitos',
        instrucaoSenhaNumero: 'Ao menos um número',
        instrucaoSenhaTitulo: 'A sua senha deve possuir:',
        maisMedicosResidencia: 'Mais médicos / PROVAB / Residência',
        mensagemFaltaVinculoSolicitacao: 'Não identificamos, no seu cadastro, vínculo de atuação profissional na área da saúde. Com isso, você terá acesso apenas ao serviço de Tele-educação (webconferências e minicursos)',
        mensagemFaltaVinculoSolicitacao: 'Não identificamos, no seu cadastro, vínculo de atuação profissional na área da saúde. Com isso, você terá acesso apenas ao serviço de Tele-educação (webconferências e minicursos)',
        mensagemSolicitacaoCadastroAzureAd: 'A seguir, você deverá vincular seu e-mail da EBSERH (@ebserh.gov.br) para finalizar o seu cadastro.',
        novaSenha: 'Nova senha',
        novaSenhaConfirmacaoCpf: 'A senha não pode conter o CPF',
        novaSenhaConfirmacaoDataNascimento: 'A senha não pode conter sua data de nascimento',
        novaSenhaConfirmacaoEmail: 'A senha não pode conter o Email',
        novaSenhaConfirmacaoLogin: 'A senha não pode conter o Login de usuário',
        novaSenhaConfirmacaoNome: 'A senha não pode conter o nome',
        novaSenhaConfirmacaoSenhaIgual: 'A nova senha e a confirmação de senha devem ser iguais.',
        novoCadastro: 'Solicitar novo cadastro',
        novoCadastroPaciente: 'Solicitar novo cadastro de paciente',
        numeroConselhoInvalido: 'Número de conselho inválido',
        opcaoNaoAtuoProfissionalmente: 'Não atuo profissionalmente na área da saúde',
        opcaoVoltarPreencherVinculo: 'Voltar e preencher o vínculo',
        profissaoInvalida: 'Profissão inválida',
        senhaAoMenosUmaMaiuscula: 'A senha deve conter pelo menos uma letra maiúscula.',
        senhaAoMenosUmaMinuscula: 'A senha deve conter pelo menos uma letra minúscula.',
        senhaAoMenosUmCaracter: 'A senha deve conter pelo menos um caractere especial.',
        senhaAoMenosUmNumero: 'A senha deve conter pelo menos um número.',
        senhaMinimo9Caracteres: 'A senha deve ter no mínimo 8 caracteres.',
        senhaSemEspacos: 'A senha não pode conter espaços.',
        sucessoCadastroPaciente: 'Dados da solicitação de cadastro salvos com sucesso.',
        termosAutorizacoes: 'Termos e autorizações',
        vinculos: 'Vínculos'
    },
    SolicitacaoInstituicaoFormIndex: {
        adicionarInstituicao: 'Adicionar instituição'
    },
    SolicitacaoInstituicaoFormPesquisa: {},
    SolicitacaoInstituicaoIndex: {
        confirmarExclusao: 'Você confirma a exclusão desta instituição?',
        nenhumaInstituicao: 'Nenhuma instituição adicionada'
    },
    SolicitacaoInstituicaoTabelaInstituicoes: {},
    SolicitacaoParticipacao: {
        confirmarExclusao: 'Você confirma a exclusão desta participação?',
        nenhumParticipacao: 'Nenhuma participação adicionada',
        participacao: 'Participação'
    },
    SolicitacaoParticipacaoFormIndex: {},
    SolicitacaoParticipacaoFormOpcoes: {
        participacaoConflitante: 'Não podem existir períodos no mesmo intervalo de tempo dos já existentes'
    },
    solicitacaoPendenteAtualizada: 'A situação da solicitação foi atualizada para pendente',
    SolicitacaoTermoIndex: {
        divulgacaoEmail: 'Aceito receber divulgação por email.',
        infoDivulgacao: `As divulgações enviadas semanalmente informam sobre a agenda de atividades de Tele-educação e as novidades dos serviços de Telediagnóstico e Teleconsultoria.
            Obs.: O Telessaúde UFSC reserva-se ao direito de enviar comunicados importantes sobre o uso e a oferta dos serviços sem autorização de seus usuários.`,
        leiaTermo: 'Leia o Termo aqui'
    },
    sucessoDeslogarUsuario: 'Funcionário deslogado com sucesso',
    suporte: 'Suporte',
    telediagnostico: 'Telediagnóstico',
    TermoConsentimento: {
        erroSalvarTcle: 'Não foi possível salvar o termo de consentimento. Por favor, tente novamente mais tarde.',
        textoTermo: (cpf, nome) => `<b>TERMO DE CONCORDÂNCIA / AUTORIZAÇÃO TERMO DE CONCORDÂNCIA / AUTORIZAÇÃO PARA SERVIÇOS DE TELEMEDICINA</b>
                                <br/>
                                <br/>
                                <b>ATENÇÃO:</b> A leitura, compreensão e concordância do presente termo é indispensável para o acesso aos serviços de telemedicina oferecidos pelo 
                                <b>Sistema de Telemedicina e Telessaúde</b>. Se tiver qualquer dúvida, peça explicações ao profissional de saúde que lhe prestou ou prestará atendimento. 
                                Não tenha pressa, o importante é a sua ciência que os serviços de Telemedicina somente são realizados após seu pleno e livre consentimento.
                                <br/>
                                <br/>
                                De acordo com o disposto na Resolução nº 2.314 de 20 de abril de 2022, do Conselho Federal de Medicina, a telemedicina é o exercício da medicina mediado por Tecnologias Digitais, de Informação e de Comunicação (TDICs), para fins de assistência, educação, pesquisa, prevenção de doenças e lesões, gestão e promoção de saúde.
                                <br/>
                                <br/>
                                Ao ser atendido(a) no consultório virtual, as informações registradas no chat durante a teleconsulta, assim como qualquer arquivo anexado no sistema, ficarão gravadas no STT para que sejam integrantes das
                                suas informações, com garantia de guarda, sigilo e confidencialidade, conforme resoluções do CFM, do Código de Ética Médica e da Lei Geral de Proteção de Dados
                                (Lei nº 13.709/2018). Seus dados pessoais que são registrados ou enviados na plataforma, encontram-se em ambiente seguro e o acesso a esses dados é controlado
                                e restrito. O(a) médico(a) ou profissional de saúde responsável pelo seu atendimento, acessará seus dados clínicos e exames, bem como toda e qualquer documentação
                                médica referente ao seu caso, para auxiliar no diagnóstico e tratamento. Você sempre terá acesso às informações do seu atendimento, toda vez que fizer o login no
                                consultório virtual. Seus dados poderão ser utilizados pelo Ministério da Saúde, Secretarias de Saúde e/ou Universidade Federal de Santa Catarina, sem a sua identificação,
                                assegurando o ANONIMATO para realização de pesquisa e inovação na área de inteligência artificial, que permitirá o desenvolvimento de novos métodos de identificação de doenças e novos protocolos para tratamento de comorbidades.
                                <br/>
                                Este termo de consentimento pode ser revogado, a qualquer momento, a seu pedido, sem qualquer ônus ou prejuízo à você. 
                                <br/>
                                Por meio deste termo, eu, ${nome} inscrito(a) no CPF sob o nº ${cpf} manifesto estar ciente, ter compreendido e estar de acordo com as condições estabelecidas.
                                <br/>
                                OBS.: O presente Termo pode ser atualizado periodicamente em decorrência de mudanças legais e éticas, sendo que sereivocê será comunicado por meio de correio eletrônico para nova ratificação de concordância.`,
        textoTermoTeleatendimento: (cpf, nome) => `A Teleconsulta é a consulta médica não presencial, mediada por Tecnologias Digitais, de Informação e de Comunicação (TDICs), com médico e paciente
                                localizados em diferentes espaços. É direito, tanto do paciente quanto do médico, optar pela interrupção do atendimento a distância, assim como optar pela consulta presencial
                                (Resolução CFM nº 2.314/2022).
                                <br/>
                                A plataforma digital utilizada na minha Teleconsulta segue as normas HIPAA compliance para assegurar a proteção e o sigilo das informações contra vazamento de dados 
                                (garante que a minha teleconsulta é segura e que os dados não serão vazados na internet).
                                <br/>
                                Durante a Teleconsulta, devo estar em local reservado (local separado, silencioso e que permita que eu possa me concentrar na consulta) sem a
                                presença de outras pessoas, exceto as que o(a) médico(a) considerar necessárias para o processo do atendimento.
                                <br/>
                                Todas as informações estão garantidas pelo sigilo médico, e como tal, devo garantir que permaneçam confidenciais (tanto o médico quanto eu devemos
                                garantir que o conteúdo da consulta permaneça em sigilo). Para garantia do sigilo, não poderei fazer qualquer gravação sem autorização explícita do(a)
                                médico(a) que prestou atendimento.
                                <br/>
                                Todas as informações relatadas por mim são verdadeiras, e eu compreendo que devo relatar ao médico as informações claras, pois elas são fundamentais
                                para a investigação médica. O(a) médico(a) fará o registro desse atendimento no meu prontuário médico.
                                <br/>
                                Caso o(a) médico(a) considere necessário, poderá solicitar meu comparecimento presencial para a realização de exame físico ou exames complementares.
            <br/>
            <br/>
            Por este instrumento particular, informo que me foram explicadas as características da Teleconsulta, as minhas responsabilidades e as do (a) médico(a) que me atenderá.
            <br/>
            Confirmo que li, compreendi e concordo com todos os termos deste Termo de Consentimento Livre e Esclarecido para Teleconsulta.
            <br/>
            <b>Nome do paciente:</b> ${nome}
            <br/>
            <b>CPF do paciente:</b> ${cpf}
            <br/>`
    },
    timeoutToken: 'Timeout (em minutos) para tokens de usuário',
    tipoEstabelecimento: 'Tipo de estabelecimento',
    tipoOcupacao: 'Tipo de ocupação',
    tituloPagina: 'Alteração de pacientes',
    todas: 'Todas',
    tornarPendente: 'Tornar pendente',
    triagem: 'Triagem',
    TriagemAcessoInstituicao: {
        aprovado: 'Aprovado',
        aprovar: 'Aprovar',
        confirmarAprovar: 'Você confirma a aprovação dessa solicitação de acesso?',
        confirmarRejeitar: 'Você confirma a rejeição dessa solicitação de acesso?',
        conselhoTrabalho: 'Conselho de trabalho',
        dadosRejeicao: 'Dados da Rejeição',
        dadosSolicitacao: 'Dados da solicitação',
        dataAvaliacao: 'Data da avaliação',
        dataSolicitacao: 'Data da solicitação',
        datHoraSolicitacao: 'Data/Hora da solicitação',
        funcionarioAvaliador: 'Funcionario avaliador',
        funcionarioSolicitante: 'Funcionário solicitante',
        instituicao: 'Instituição',
        instituicaoSolicitada: 'Instituição solicitada',
        instituicoesCadastradas: 'Instituições cadastradas',
        motivoRejeicao: 'Motivo da rejeição',
        motivoSolicitacao: 'Motivo da solicitação',
        nomeSolicitante: 'Nome do solicitante',
        pendente: 'Pendente',
        rejeitado: 'Rejeitado',
        rejeitar: 'Rejeitar',
        rejeitarSolicitacaoAcesso: 'Rejeitar solicitação de acesso a instituição',
        rqe: 'RQE',
        situacao: 'Situação',
        situacaoSolicitacao: 'Situação da solicitação',
        tituloPagina: 'Triagem de acesso a instituições'
    },
    triagemAutoCadastro: 'Triagem de auto-cadastro',
    triarAutoCadastro: 'Triar auto-cadastro',
    trocaPonto: 'Troca de ponto',
    url: 'URL',
    url_base: 'URL base',
    usuariosNomeEmailVinculo: 'Usuários (e-mail e vínculo)',
    versao_dcm4chee: 'Versão dcm4chee'
};
