"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importIconesModulos = void 0;
var importAll = function importAll(r) {
  var images = {};
  r.keys().forEach(function (fileName) {
    var key = fileName.replace('./', '').replace('.svg', '');
    images[key] = r(fileName);
  });
  return images;
};
var iconesCinza = importAll(require.context('./cinza', false, /\.svg$/));
var iconesBranco = importAll(require.context('./branco', false, /\.svg$/));
var importIconesModulos = function importIconesModulos() {
  var cor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'branco';
  if (cor === 'branco') return iconesBranco;
  return iconesCinza;
};
exports.importIconesModulos = importIconesModulos;