module.exports = {
    abrirJanelaVideo: 'Abrir janela de vídeo',
    anexar: 'Anexar',
    anexarArquivo: 'Anexar arquivos',
    anexos: 'Anexos',
    ano: 'Ano de nascimento',
    atendimento: 'Em atendimento',
    atendimentoEncerrado: 'O seu atendimento foi encerrado.',
    atendimentos: 'Atendimentos',
    avisoSemConexao: 'Ops! Parece que perdemos a conexão com o servidor de Suporte. Verifique sua conexão com a internet e aguarde, o sistema será recarregado automaticamente assim que a conexão for restabelecida. Se preferir, você pode recarregar manualmente clicando <a href="."><b>aqui</b></a>.',
    buscandoCpf: `Aguarde. Buscando dados do seu CPF...`,
    chamadaVideo: 'Iniciar atendimento por vídeo',
    chat: 'Chat',
    cnesInvalido: cnes => `A instituição com o CNES número ${cnes} não existe ou não está habilitada para utilizar o módulo de teleatendimento.`,
    concluido: 'Concluído',
    concordo: 'Concordo',
    confirmarFinalizarAtendimento: 'Você tem certeza que deseja finalizar este atendimento?',
    dadosPessoais: 'Dados pessoais',
    dia: 'Dia de nascimento',
    digiteMensagem: 'Digite sua mensagem...',
    discordo: 'Discordo',
    encaminhando: 'Encaminhando...',
    encaminharAtendimento: 'Encaminhar atendimento',
    enviandoArquivo: 'Enviando arquivo...',
    enviandoCadastro: 'Enviando cadastro...',
    enviarMensagem: 'Enviar mensagem',
    espera: 'Em espera',
    fecharAtendimento: 'Fechar janela de atendimento',
    fecharChamadaVideo: 'Fechar atendimento por vídeo',
    fecharHistorico: 'Fechar histórico',
    semHistorico: 'Nenhum registro de atendimento encontrado.',
    finalizarAtendimento: 'Finalizar atendimento',
    historico: 'Histórico',
    historicoAtendimento: 'Histórico dos atendimentos',
    inserirAnexo: 'Inserir anexo',
    jaOnline: 'Você já está conectado no módulo de Teleatendimento através de outra aba, navegador ou dispotivo.',
    menuAtendimento: 'Atendimento',
    mes: 'Mês de nascimento',
    nenhumaMensagemEnviadaAtendimento: 'Nenhuma mensagem de texto foi enviada neste atendimento.',
    nenhumAnexoEnviadaAtendimento: 'Nenhum anexo foi enviado neste atendimento.',
    notificacaoFecharAtendimento: 'O atendimento atual ainda está em andamento. Ao fechar a janela o atendimento será encerrado. Você confirma esta ação?',
    notificacaoNovaMensagemAnexo: 'Envio um anexo.',
    notificacaoNovoAtendimento: 'Você já está realizando um atendimento neste momento. Se você iniciar um novo atendimento ou visualizar as mensagens de outro atendimento, o atual será finalizado. Você confirma esta ação?',
    notificacaoSolicitacaoAtendimento: 'Um usuário solicitou atendimento.',
    notificacaoTabNovaMensagem: '✉️ Nova mensagem enviada',
    notificacaoTabSolicitacaoAtendimento: '🟠 Atendimento solicitado',
    observacao: 'Digite uma observação',
    preCadastro: 'Para ir ao teleatendimento você deve fazer este pequeno pré-cadastro.',
    preenchaCpf: 'Preencha o CPF para começar o teleatendimento.',
    salvando: 'Salvando termo de consentimento...',
    selecionarEmoji: 'Selecionar emoji',
    selecioneArquivo: 'Selecione um arquivo',
    selecioneInstituicao: 'Instituição para atendimento',
    selecioneUsuario: 'Selecione um usuário para iniciar ou continuar um atendimento.',
    semAtendenteEncaminhar: 'Não há outros atendentes disponíveis para encaminhar o atendimento.',
    solicitarAtendimento: 'Solicitar atendimento',
    suporte: 'Suporte',
    teleatendimento: 'Teleatendimento',
    teleatendimentoPaciente: 'Serviço de teleatendimento a pacientes',
    termo: 'Termo de consentimento',
    textoAguarde: nome => `Olá, ${nome}!`,
    textoAguardeAtendimento: 'Aguarde seu atendimento.',
    textoAguardeSemAtendentes: 'No momento não há atendentes.',
    textoAtendenteConectado: 'Atendente conectado, por favor retire sua dúvida.',
    textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
    textoCliqueAbrirHistorico: 'Verifique seu histórico <a href="javascript:void(0)"><b>aqui</b></a>.',
    textoCliqueSolicitarAtendimento: 'Solicite seu atendimento <a href="javascript:void(0)"><b>aqui</b></a>.',
    usuariosParaAtendimento: 'Usuários para atendimento',
    validar: 'Validar',
    validarDados: 'Para validarmos seu cadastro, por favor digite os dados solicitados abaixo.'
}